import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				OakBridge Legal Solutions
			</title>
			<meta name={"description"} content={"Gerechtigkeit stärken, Seelenfrieden fördern"} />
			<meta property={"og:title"} content={"OakBridge Legal Solutions"} />
			<meta property={"og:description"} content={"Gerechtigkeit stärken, Seelenfrieden fördern"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="text">
				OakBridge Legal Solutions
			</Override>
		</Components.Header2>
		<Section padding="100px 0 100px 0" background="--color-darkL2" quarkly-title="FAQ-7">
			<Text margin="0px 0px 2rem 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				Häufig gestellte Fragen
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							1. Welche juristischen Dienstleistungen bieten Sie an?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Wir bieten ein breites Spektrum an juristischen Dienstleistungen an, einschließlich Vertragsrecht, Familienrecht, Arbeitsrecht, Strafrecht, Immobilienrecht und vieles mehr.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							2. Wie lange dauert es, bis mein Fall bearbeitet wird?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Die Bearbeitungszeit hängt von der Komplexität des Falls ab. Einfache Fälle können innerhalb weniger Tage gelöst werden, während komplexere Fälle mehrere Wochen oder Monate in Anspruch nehmen können. Wir informieren unsere Mandanten immer über den voraussichtlichen Zeitrahmen nach einer ersten Bewertung.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							3. Muss ich einen Termin im Voraus vereinbaren?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Ja, wir empfehlen, im Voraus einen Termin zu vereinbaren, um eine effiziente und qualitativ hochwertige Beratung zu gewährleisten. Sie können dies über unsere Website oder telefonisch tun.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							4. Bieten Sie eine Garantie auf Ihre Dienstleistungen an?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Wir stehen hinter der Qualität unserer Arbeit und bieten eine Zufriedenheitsgarantie. Die Details und Bedingungen der Garantie können je nach Dienstleistung variieren und werden bei Vertragsabschluss besprochen.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							5. Können Sie Dokumente für meinen Fall beschaffen und vorbereiten?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Ja, wir arbeiten mit zahlreichen Quellen und Behörden zusammen, um alle notwendigen Dokumente für Ihren Fall zu beschaffen und vorzubereiten. Wir stellen sicher, dass alle Unterlagen korrekt und vollständig sind, um den besten Ausgang für Ihren Fall zu gewährleisten.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							6. Bieten Sie Rechtsberatung auch online an?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Ja, wir bieten auch Online-Beratung an, um Ihnen flexible und bequeme juristische Unterstützung zu bieten. Sie können einen Online-Termin über unsere Website buchen.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Header2 />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66028e06867c86001f1d8b21"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});